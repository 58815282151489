/**
 * Language Switch
 * 
 * @dependencies
 * * jquery
 * * kmcookie
 * 
 * @dependents
 * * $(document).ready(function () { ... }); – (erco.js)
 * * data attribute [data-trigger-sprachwechsel] used in pdb-api (dist/struktur/php/global/sprachen-km.php)
 * 
 * @commit Initial commit 2.0
 * 
 * @note 
 */

sprachwahl = {
  start: function () {
    $('[data-trigger-sprachwechsel]').on('click', function (event) {
      event.preventDefault();

      // Element und Attribute zuweisen
      var ele = this;
      var url = $(ele).attr("href");
      var sprache = $(ele).attr("data-trigger-sprachwechsel");

      // Abbruch wenn undefined
      if (!sprache) return;

      // Trigger setTechnicalEnviroment in language-switch.js
      const tuLanguage = sprache === 'en_us' ? 'us' : sprache;
      document.dispatchEvent(new CustomEvent('setTechnicalEnviroment', { detail: { isoLanguage: tuLanguage } }));

      // Cookie setzen
      kmcookie.setzen("erco-lang", sprache);

      // Url aufrufen
      location.href = url;
    });

    document.addEventListener('selectLanguage', function (e) {
      if (e.detail && e.detail.language) {
        const element = document.querySelector('[data-trigger-sprachwechsel="' + e.detail.language + '"]');
        if (element) {
          element.click();
        }
      }
    });
  }
}