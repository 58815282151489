class TechnicalEnviromentMessage {
  static LOCAL_STORAGE_KEY = 'tuMessage';

  // List of active pages where the message should be shown
  static ON_PAGES_ACTIVE = [
    'body[data-id-area="1"][data-bool-gattungsobjekt="n"]', // Product family pages
    'body[data-id-objekt="7093"]',// Product Finder
    'body[data-id-objekt="7449"]', // Stromschienen Konfigurator
    'body[data-id-objekt="7678"]', // Invia Konfigurator
  ]

  static MAPPINGS_TU = {
    'de': 'global',
    'en': 'global',
    'fr': 'global',
    'es': 'global',
    'it': 'global',
    'nl': 'global',
    'us': 'usa',
  }

  static pageIso = null;

  /**
   * Initialize the message
   *  
   */
  static init() {
    document.addEventListener('setTechnicalEnviroment', (event) => {
      if (!event.detail.isoLanguage) {
        return;
      }
      const isoLanguage = event.detail.isoLanguage;

      TechnicalEnviromentMessage.setTechnicalEnviorment(isoLanguage);
    });

    if (!TechnicalEnviromentMessage.isActivePage()) {
      return;
    }

    let userIso = kmcookie.lesen('erco-lang')
    userIso = userIso === 'en_us' ? 'us' : userIso;

    const userTU = TechnicalEnviromentMessage.MAPPINGS_TU[userIso] ?? '';

    const contactJSONIso = (userIso === 'en') ? 'gb' : userIso || '';

    const urlContactJSON = `${confEnv['pdb_api_url']}/contact/get_contact_json/${contactJSONIso}`;

    fetch(urlContactJSON).then(response => response.json()).then(data => {
      const localizedTU = data.technical_enviroment;
      const localizedIso = data.preferred_language;

      let pageIso = k3vars.S_Sprache;
      pageIso = pageIso === 'en_us' ? 'us' : pageIso;
      const pageTU = TechnicalEnviromentMessage.MAPPINGS_TU[pageIso];
      let copyTUBanner = null;

      if (pageTU === 'global') {
        copyTUBanner = data.tu_banner_copy_global
      } else {
        copyTUBanner = data.tu_banner_copy_usa
      }

      const storageObject = window.toolkit.getLocalStorageObject(TechnicalEnviromentMessage.LOCAL_STORAGE_KEY);
      let preSelectedIso = null;

      const renderElement = document.querySelector('[data-tu-render-mustache]');
      if (!renderElement)
        return;

      globals.renderSingleMustacheTemplate(renderElement, data).then((data) => {
        renderElement.innerHTML = data;

        // Check if the user has already confirmed the TU
        if (storageObject && storageObject.selectedTU && storageObject.selectedIso) {
          const confirmedTU = storageObject.selectedTU;
          const confirmedIso = storageObject.selectedIso;

          if (pageTU === confirmedTU && pageTU === userTU) {
            return;
          } else {
            preSelectedIso = confirmedIso;
          }

          // Check if the user has a preferred TU saved in the language cookie
        } else if (userIso) {
          if (pageTU === userTU) {
            return;
          } else {
            preSelectedIso = userIso;
          }
        } else if (pageTU === localizedTU) {
          return;
        } else {
          preSelectedIso = localizedIso;
        }

        TechnicalEnviromentMessage.show(preSelectedIso, pageIso, copyTUBanner);
      });
    }).catch(error => {
      console.log('error', error);
      return;
    });
  }

  /**
   * Show the message
   *  
   * @param {string} preselectedIso - The iso code which should be preselected
   * @param {string} pageIso - The current iso code of the page
   * @param {string} copyTU - The copy of the TU message
   */
  static show(preselectedIso, pageIso, currentTU) {
    const tuInteractionHandlesElement = document.querySelector('[data-overlay-item="tu-message"]');
    if (!tuInteractionHandlesElement) {
      return;
    }
    const copyTU = document.querySelector('[data-tu-copy]');
    copyTU.innerHTML = currentTU;

    const selectTU = document.querySelector('[data-tu-select-tu]');
    selectTU.value = preselectedIso;

    tuInteractionHandlesElement.addEventListener('click', function (event) {
      if (event.target.dataset.tuBtn === 'nav_tu_confirm') {

        const selectTU = document.querySelector('[data-tu-select-tu]');
        let selectedISO = selectTU.options[selectTU.selectedIndex].value;
        TechnicalEnviromentMessage.setTechnicalEnviorment(selectedISO);

        if (selectedISO !== pageIso) {
          selectedISO = selectedISO === 'us' ? 'en_us' : selectedISO;
          document.dispatchEvent(new CustomEvent('selectLanguage', { detail: { language: selectedISO } }));
        }

        kmcookie.setzen("erco-lang", selectedISO);
        TechnicalEnviromentMessage.hide();
      } else if (event.target.dataset.tuBtn === 'nav_tu_cancel') {
        TechnicalEnviromentMessage.hide();
      };
    });
    document.dispatchEvent(new CustomEvent('showTUMessage'));
  }

  static isActivePage() {
    return TechnicalEnviromentMessage.ON_PAGES_ACTIVE.some(selector => document.querySelector(`${selector}`));
  }

  static setTechnicalEnviorment(isoLanguage) {
    const isActivePage = TechnicalEnviromentMessage.isActivePage()
    if (!isActivePage) {
      return;
    }
    const selectedTU = TechnicalEnviromentMessage.MAPPINGS_TU[isoLanguage];
    const storageObject = {
      confirmed: true,
      selectedTU,
      selectedIso: isoLanguage
    };
    window.toolkit.setLocalStorageObject(TechnicalEnviromentMessage.LOCAL_STORAGE_KEY, storageObject, 365 * 24 * 60) // Expires in 1 year
  }

  /**
   * Hide the message
   */
  static hide() {
    // hide cookieMessage
    document.dispatchEvent(new CustomEvent('hideTUMessage'));
  }

}
